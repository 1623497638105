import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import { resetPassword } from '../../actions/authActions';
import { closeMenu } from '../../actions/appActions';
import { CLEAR_SUCCESS_MESSAGE } from '../../types/authTypes';

import '../Register/Register.css';
import './ResetPassword.css';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const { user, loading, errors, successMessage } = authSelector;

    const [phone, setPhone] = useState('');

    const onSubmit = e => {
        e.preventDefault();

        dispatch(resetPassword(phone));
    };

    useEffect(() => {
        dispatch(closeMenu());

        return() => {
            dispatch({ type: CLEAR_SUCCESS_MESSAGE });
        };
    }, [dispatch]);

    return (
        <Fragment>
            <Header isLogin />
            {!user ? (
                <Content>
                    <h1>One Time Temporary Pin</h1>
                    {successMessage ? (
                        <>
                            <div className="resetPassword__success">{successMessage}</div>
                            <div className='resetPassword__buttonContainer'>
                                <NavLink activeClassName="active" to="/login">Back to login</NavLink>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='resetPassword__message'>Enter your pin/password and we'll send you a one time temporary pin. You can then change your pin in your profile.</div>
                            <form onSubmit={onSubmit} className='resetPassword_form'>
                                <div className="form-group">
                                    <label className="form__label" htmlFor="phone">Email / Mobile Number</label>
                                    <input className="form__input" type="text" id="phone" name="phone" value={phone} autoComplete={false} onChange={(e) => setPhone(e.target.value)} />
                                    <div className="error">{errors?.phone?.message}</div>
                                </div>
                                <div className="form__buttons">
                                    <button aria-label="Sign in" className="form__button form__button--inline" type="submit">Send temporary pin <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                                </div>
                            </form>
                        </>
                    )}
                </Content>
                ) : (
                    <Redirect push to="/" />
                )
            }
            <BottomNav />
        </Fragment>
    )
}

export default ResetPassword;
