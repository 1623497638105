import Cookie from 'js-cookie';

import { AppAPI } from '../services/api';

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    CHECK_MEMBERSHIP_REQUEST,
    CHECK_MEMBERSHIP_SUCCESS,
    CHECK_MEMBERSHIP_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    LOGOUT,
    VERIFY_REQUEST,
    VERIFY_SUCCESS,
    VERIFY_FAIL,
    REVALIDATE_REQUEST,
    REVALIDATE_FAIL,
    REVALIDATE_SUCCESS,
} from '../types/authTypes';
import { addHours } from '../functions/utils';

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_REQUEST });

        const { data } = await AppAPI.post(
            '/app_handler.php',
            { email, password },
            { headers: { 'WG-Method': 'LOGIN' } }
        );

        if (data) {
            Cookie.set('accessToken', data.jwt, { expires: addHours(new Date(), 365) });
            Cookie.set('user', JSON.stringify(data.user));

            dispatch({ type: LOGIN_SUCCESS, payload: data.user });
        } else {
            dispatch({
                type: LOGIN_FAIL,
                payload: {
                    errors: 'Your login details do not match our records. \n\nIf you have an account with us and cannot remember your password, you may use the password reset routine which will email you a new password.\n\n',
                },
            });
        }
    } catch (error) {
        dispatch({ type: LOGIN_FAIL, payload: error?.response?.data?.errors });
    }
};

export const revalidate = () => async (dispatch) => {
    try {
        dispatch({ type: REVALIDATE_REQUEST });

        const { data } = await AppAPI.get(
            '/app_handler.php',
            { headers: {
                'WG-Method': 'REVALIDATE',
                'WG-Key': Cookie.get('accessToken')
            }
        });

        if (data) {
            Cookie.set('accessToken', data.jwt, { expires: addHours(new Date(), 365) });
            Cookie.set('user', JSON.stringify(data.user));

            dispatch({ type: REVALIDATE_SUCCESS, payload: data.user });
        }
    } catch (error) {
        dispatch({ type: REVALIDATE_FAIL, payload: error?.response?.data?.errors });
        Cookie.remove('user');
    }
};

export const checkMembership = () => async (dispatch) => {
    try {
        dispatch({ type: CHECK_MEMBERSHIP_REQUEST });

        const { data } = await AppAPI.get('/app_handler.php', {
            headers: {
                'WG-Method': 'CHECK_SUBSCRIPTION',
                'WG-Key': Cookie.get('accessToken'),
            },
        });

        if (data) {
            dispatch({ type: CHECK_MEMBERSHIP_SUCCESS, payload: data });
        } else {
            dispatch({
                type: CHECK_MEMBERSHIP_FAILURE,
                payload: {
                    errors: 'Unable to verify account.  Please ensure you are connected to the internet.',
                },
            });
        }
    } catch (error) {
        dispatch({
            type: CHECK_MEMBERSHIP_FAILURE,
            payload: error?.response?.data?.errors,
        });
    }
};

export const register =
    (
        firstname,
        lastname,
        email,
        phone,
        password,
        collegeUniversity,
        societies,
        locationParam,
        token
    ) =>
    async (dispatch) => {
        try {
            dispatch({ type: REGISTER_REQUEST });

            const { data } = await AppAPI.post(
                '/app_handler.php',
                {
                    firstname,
                    lastname,
                    email,
                    phone,
                    password,
                    college_or_university: collegeUniversity,
                    sports_team_or_society: societies,
                    location_id: locationParam,
                    token,
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'WG-Method': 'REGISTER',
                    },
                }
            );

            if (data) {
                Cookie.set('accessToken', data.jwt, { expires: addHours(new Date(), 365) });
                Cookie.set('user', JSON.stringify(data.user));

                dispatch({ type: REGISTER_SUCCESS, payload: data.user });
            } else {
                dispatch({
                    type: REGISTER_FAIL,
                    payload: {
                        errors: 'Unable to Register account.  Please ensure you are connected to the internet.',
                    },
                });
            }
        } catch (error) {
            dispatch({
                type: REGISTER_FAIL,
                payload: error?.response?.data?.errors,
            });
        }
    };

export const logout = () => (dispatch) => {
    dispatch({ type: LOGOUT });
    Cookie.remove('accessToken');
    Cookie.remove('user');
};

export const resetPassword = (phone) => async (dispatch) => {
    try {
        dispatch({ type: RESET_PASSWORD_REQUEST });

        const { data } = await AppAPI.post(
            '/app_handler.php',
            { phone },
            { headers: { 'WG-Method': 'RESET_PASSWORD' } }
        );

        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.message });
    } catch (error) {
        dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: error?.response?.data?.errors,
        });
    }
};
