import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import Content from '../../components/layout/Content';
import { register } from '../../actions/authActions';
import { closeMenu } from '../../actions/appActions';

import 'react-datepicker/dist/react-datepicker.css';
import './Register.css';

const years = [];
const currentYear = new Date().getFullYear();

for (let i = currentYear; i >= 1920; i--) {
    years.push(i);
}

const RegisterForm = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const authSelector = useSelector((state) => state.auth);
    const { loading, errors } = authSelector;
    const appSelector = useSelector((state) => state.app);
    const { fieldOptions, fieldOptionsByOptionID, sportsListByUniversityID } = appSelector;

    const captchaRef = useRef(null);
    const termsEl = useRef(null);
    const [termsError, setTermsError] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [collegeUniversity, setCollegeUniversity] = useState('');
    const [societies, setSocieties] = useState([]);
    const [societySelect, setSocietySelect] = useState('');

    const [isUniversity, setIsUniversity] = useState(false);

    const urlParams = new URLSearchParams(location.search);
    const locationParam = urlParams.get('location');

    const onSubmit = async (e) => {
        e.preventDefault();

        if (termsEl.current.checked) {
            setTermsError(null);

            const token = captchaRef.current.getValue();

            dispatch(
                register(
                    firstName,
                    lastName,
                    email,
                    phone,
                    password,
                    collegeUniversity,
                    societies,
                    locationParam,
                    token
                )
            );
        } else {
            setTermsError('Please check the terms to continue.');
        }
    };

    const handleCollegeUniversityChange = (e) => {
        const { value } = e.target;

        setCollegeUniversity(value);
        setSocieties([]);

        if (
            value === '26' ||
            value === '27' ||
            value === '28' ||
            value === '29' ||
            value === '64' ||
            value === '63'
        ) {
            setIsUniversity(true);
        } else {
            setIsUniversity(false);
        }
    };

    const handleSocietiesChange = (e) => {
        const { value } = e.target;

        setSocieties((prev) => [...prev, value]);
    };

    const handleRemoveSociety = (id) => {
        if (id !== '') {
            setSocieties(
                (prev) => [...prev.filter((society) => society !== id)],
                () => {
                    setSocietySelect('');
                }
            );
        }
    };

    useEffect(() => {
        dispatch(closeMenu());
    }, [dispatch]);

    useEffect(() => {
        if (errors) {
            captchaRef.current.reset();
        }
    }, [errors]);

    return (
        <Content>
            <h1>Register</h1>
            <span className="login__welcome">Please fill out all fields</span>
            <form className="login__form" onSubmit={onSubmit}>
                <div className="form-group">
                    <label className="form__label" htmlFor="firstName">
                        First Name
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        autoComplete="off"
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <div className="error">{errors?.firstname?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="lastName">
                        Last Name
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        autoComplete="off"
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <div className="error">{errors?.lastname?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="email">
                        Email
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="error">{errors?.email?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="phone">
                        Mobile Phone
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        autoComplete="off"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <div className="error">{errors?.phone?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor='collegeOrUniversity'>
                        College or University
                    </label>
                    <select
                        id="collegeOrUniversity"
                        className="form__select"
                        style={{ width: '100%' }}
                        name="collegeUniversity"
                        value={collegeUniversity}
                        onChange={handleCollegeUniversityChange}
                    >
                        <option value="">
                            -- Please select an option --
                        </option>
                        {fieldOptions?.college_or_university?.map(
                            (field) => (
                                <option value={field?.field_option_id}>
                                    {field?.option_name}
                                </option>
                            )
                        )}
                    </select>
                    <div className="error">
                        {errors?.college_or_university?.message}
                    </div>
                </div>
                {isUniversity && fieldOptions?.[sportsListByUniversityID[collegeUniversity]] && (
                    <React.Fragment>
                        <div className="form-group">
                            <label
                                className="form__label"
                                htmlFor="day"
                            >
                                Sport team or Society(s)
                            </label>
                            <select
                                className="form__select"
                                style={{ width: '100%' }}
                                name="societies"
                                value={societySelect}
                                onChange={handleSocietiesChange}
                            >
                                <option value="">
                                    -- Please select an option --
                                </option>
                                {fieldOptions?.[sportsListByUniversityID[collegeUniversity]]?.map(
                                    (field) => (
                                        <option
                                            value={
                                                field?.field_option_id
                                            }
                                        >
                                            {field?.option_name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        {societies.length > 0 && (
                            <div class="form__societies">
                                {societies.map((society) => (
                                    <div className="society">
                                        <span>
                                            {
                                                fieldOptionsByOptionID[
                                                    society
                                                ]
                                            }
                                        </span>
                                        <span
                                            className="close"
                                            onClick={() =>
                                                handleRemoveSociety(
                                                    society
                                                )
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </React.Fragment>
                )}
                <div className="form-group">
                    <label className="form__label" htmlFor="password">
                        Pin <span className="form__labelinfo">Create a pin (min 4 characters)</span>
                    </label>
                    <input
                        className="form__input"
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        autoComplete="off"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="error">{errors?.password?.message}</div>
                </div>
                <div className="form-group--checkbox">
                    <label htmlFor="terms" className="form__label">
                        I understand that my contact details will be retained
                        for future check in ease of use and marketing purposes.
                        You'll be able to update your communication preferences
                        from your profile
                        <input
                            className="form__checkbox"
                            type="checkbox"
                            id="terms"
                            name="terms"
                            checked={true}
                            ref={termsEl}
                        />
                    </label>
                    <div className="error">{termsError}</div>
                </div>
                <div className="formGroup">
                    <ReCAPTCHA
                        sitekey={'6LeCBIoUAAAAAA0pIkh8_uiw8NTas5dszpno15PX'}
                        ref={captchaRef}
                    />
                    <div className="error">{errors?.token?.message}</div>
                </div>
                <div className="form__buttons">
                    <button
                        aria-label="Register"
                        disabled={loading}
                        className="form__button form__button--inline"
                        type="submit"
                    >
                        Register{' '}
                        <PulseLoader
                            loading={loading}
                            color={'#ffffff'}
                            css={'margin-left: 8px'}
                            size={5}
                        />
                    </button>
                </div>
            </form>
        </Content>
    );
};

export default RegisterForm;
