export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const CHECK_MEMBERSHIP_REQUEST = 'CHECK_MEMBERSHIP_REQUEST';
export const CHECK_MEMBERSHIP_SUCCESS = 'CHECK_MEMBERSHIP_SUCCESS';
export const CHECK_MEMBERSHIP_FAILURE = 'CHECK_MEMBERSHIP_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const LOGOUT = 'LOGOUT';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE';
export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';
export const REVALIDATE_REQUEST = 'REVALIDATE_REQUEST';
export const REVALIDATE_SUCCESS = 'REVALIDATE_SUCCESS';
export const REVALIDATE_FAIL = 'REVALIDATE_FAIL';