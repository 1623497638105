import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/layout/Header';
import BottomNav from '../../components/layout/BottomNav';
import Menu from '../../components/layout/Menu';
import RegisterForm from './RegisterForm';
import { closeMenu } from '../../actions/appActions';


import 'react-datepicker/dist/react-datepicker.css';
import './Register.css';
import '../Login/Login.css';

const Register = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const authSelector = useSelector((state) => state.auth);
    const { user, registrationCompleted } = authSelector;

    useEffect(() => {
        dispatch(closeMenu())
    }, [dispatch]);

    if (registrationCompleted && user) {
        // TODO: redirect to dashboard
        history.push('/');
    }

    return (
        <Fragment>
            <Header />
            <Menu />
            <RegisterForm />
            <BottomNav />
        </Fragment>
    );
}

export default Register;
