import Cookie from 'js-cookie';

import { AppAPI } from '../services/api';

import {
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    GET_PROFILE_FIELDS_REQUEST,
    GET_PROFILE_FIELDS_SUCCESS,
    GET_PROFILE_FIELDS_FAIL,
} from '../types/profileTypes';

export const updateProfile =
    (
        firstname,
        lastname,
        email,
        phone,
        currentPassword,
        password,
        collegeUniversity,
        societies
    ) =>
    async (dispatch) => {
        try {
            dispatch({ type: UPDATE_PROFILE_REQUEST });

            const { data } = await AppAPI.post(
                '/app_handler.php',
                {
                    firstname,
                    lastname,
                    email,
                    phone,
                    current_password: currentPassword,
                    password,
                    college_or_university: collegeUniversity,
                    sports_team_or_society: societies,
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'WG-Method': 'UPDATE_PROFILE',
                        'WG-Key': Cookie.get('accessToken'),
                    },
                }
            );

            dispatch({
                type: UPDATE_PROFILE_SUCCESS,
                payload: data.successMessage,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_PROFILE_FAIL,
                payload: error?.response.data.errors,
            });
        }
    };

export const getProfileFields = () => async (dispatch) => {
    try {
        dispatch({ type: GET_PROFILE_FIELDS_REQUEST });

        const { data } = await AppAPI.get('/app_handler.php', {
            headers: {
                'WG-Method': 'GET_PROFILE_FIELDS',
                'WG-Key': Cookie.get('accessToken'),
            },
        });

        dispatch({ type: GET_PROFILE_FIELDS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: GET_PROFILE_FIELDS_FAIL,
            payload: error?.response.data.errors,
        });
    }
};
