import { AppAPI } from '../services/api';

export const handleForm = async (url, formId, data) => {
    const fd = new FormData();

    for (const [key, value] of Object.entries(data)) {
        if (value.type === 'file') {
            if (typeof value.value === 'object') {
                // console.log('value: ' + value.value);
                // console.log('name: ' + value.value.name);
                fd.append(key, value.value, value.value.name);
            } else {
                fd.append(key, value.value);
            }
        } else {
            fd.append(key, value.value);
        }
    }

    const response = await AppAPI.post(url, fd, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Form-Id': formId,
            'Wg-Method': 'FORM_ENTRIES',
        },
    });

    return response;
};

export const addHours = (date, hours) => {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  
    return date;
  }
