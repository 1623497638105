import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import BottomNav from '../../components/layout/BottomNav';
import Menu from '../../components/layout/Menu';

import { login } from '../../actions/authActions';
import { closeMenu } from '../../actions/appActions';

import './Login.css';

const Login = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	let Array_GetVariables = String(location.search.split('?')[1]);
	Array_GetVariables = Array_GetVariables.split('&');

	const isVerified = Array_GetVariables[0] === 'verifyComplete';

	const authSelector = useSelector((state) => state.auth);
	const { user, loading, errors } = authSelector;

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const onSubmit = e => {
		e.preventDefault();

		dispatch(login(email, password));
	};

	useEffect(() => {
		dispatch(closeMenu());
	}, [dispatch]);

	return (
		<Fragment>
			<Header />
			<Menu />
			{
				!user ? (
					<div className="page login">
						<div className="page-content">
							<h1>Sign in or Register</h1>
							<span className="login__welcome">Have your QR Code Scanned on Entry</span>
							<div class="login__verify">{isVerified && (
								<Fragment>
									<div>Your account is now active</div>
									<div>Login to access QR code</div>
								</Fragment>
							)}</div>
							<form onSubmit={onSubmit}>
								<div className="form-group">
									<label className="form__label" htmlFor="email">Email / Mobile Number</label>
									<input className="form__input" type="text" id="email" name="email" value={email} autoComplete="off" onChange={(e) => setEmail(e.target.value)} />
									<div className="error">{errors?.email?.message}</div>          
								</div>
								<div className="form-group">
									<label className="form__label" htmlFor="password">Pin / Password</label>
									<input className="form__input" type="password" id="password" name="password" value={password} autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
									<div className="error">{errors?.password?.message}</div>          
								</div>
								<div className="form__buttons">
									<button aria-label="Sign in" disabled={loading} className="form__button form__button--inline" type="submit">Sign In <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
								</div>
							</form>
							<div className="text-center mt-8">
								<Link to="reset-password">Forgot your pin/password?</Link>
							</div>
							<div className="text-center mt-8">
								<Link to="register">No Account? Click here to register</Link>
							</div>
							<div className="text-center mt-8">
								<a href="https://about.membership.scot/how-does-it-work">How does it work?</a>
							</div>
						</div>
					</div>
				) : (
					<Redirect push to="/" />
				)
			}
			<BottomNav />
		</Fragment>
	)
}

export default Login;
